import { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { showCustomToast } from "../../../components/common/ToastNotify";
import { isResponseSuccess } from "../../../utils";
import { useLoginMutation } from "../services/authApiSlice";
import { postLogin } from "../services/authSlice";
import { IUserData } from "../types/interfaces";

const useLogin = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [codeInput, setCodeInput] = useState<string>();
    const [captchaCode, setCaptChCode] = useState<string>("");

    const [fetchLogin, { isLoading }] = useLoginMutation();

    const schemaResolver = yupResolver(
        yup.object().shape({
            id: yup.string().required(t("請輸入 ID")),
            password: yup.string().required(t("請輸入密碼")),
        })
    );

    const onSubmit = async (formData: IUserData) => {
        if (codeInput !== captchaCode) {
            showCustomToast.error("驗證碼錯誤");
            return;
        }

        const res = await fetchLogin(formData).unwrap();

        if (isResponseSuccess(res)) {
            dispatch(
                postLogin({
                    accessToken: res?.data?.access_token,
                    refreshToken: res?.data?.refresh_token,
                    amazeAccessToken: res?.data?.amaze_access_token,
                })
            );
            if (res?.data?.is_first_time_login) {
                navigate("/auth/init-password");
                return;
            }
        }
    };

    return { schemaResolver, onSubmit, isLoading, setCaptChCode, setCodeInput, codeInput };
};
export default useLogin;
