import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import Select from "react-select";

import { FormInputProps } from "./FormInput";

const ReactSelectInput = ({ control, name, label, labelClassName, options, isMulti, errors }: FormInputProps) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field, formState, fieldState }) => {
                const { onChange, value, ref } = field;

                return (
                    <div className='mb-2'>
                        {label && <Form.Label className={labelClassName}>{label}</Form.Label>}
                        <Select
                            className='react-select react-select-container '
                            classNamePrefix='react-select'
                            isClearable={true}
                            value={value ?? []}
                            onChange={onChange}
                            isMulti={isMulti}
                            options={options}
                            ref={ref}
                        />
                        {errors && errors[name] && (
                            <Form.Control.Feedback type='invalid' className='d-block'>
                                {errors[name]?.["message"] || errors[name]?.value?.["message"]}
                            </Form.Control.Feedback>
                        )}
                    </div>
                );
            }}
        />
    );
};
export default ReactSelectInput;
