import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../services/store";

const initialState = {
    pageTitle: {
        title: "",
        breadCrumbItems: [
            {
                label: "",
                path: "",
            },
        ],
    },
};

const pageTitleSlice = createSlice({
    name: "pageTitle",
    initialState,
    reducers: {
        changePageTitle: (state, action) => {
            return {
                ...state,
                pageTitle: action.payload,
            };
        },
    },
});

export const { changePageTitle } = pageTitleSlice.actions;
export default pageTitleSlice.reducer;

export const selectPageTitle = (state: RootState) => state.pageTitle.pageTitle;
