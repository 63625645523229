import { Navigate } from "react-router-dom";

import { selectIsNoOrgsSuperUser } from "../features/authentication";
import { useAppSelector } from "../hooks/reduxHooks";

const Root = () => {
    const isNoOrgSuperAdmin = useAppSelector(selectIsNoOrgsSuperUser);

    console.log("== 🐑  == isNoOrgSuperAdmin: ", isNoOrgSuperAdmin);

    const getRootUrl = () => {
        let url: string = "enterprise/accounts";
        return url;
    };

    const url = getRootUrl();

    if (isNoOrgSuperAdmin) {
        return <Navigate to={`/amaze/organization`} />;
    }

    return <Navigate to={`/${url}`} />;
};

export default Root;
