import { MIME_TYPES } from "../types/enum";

export const checkValidFilesType = (acceptTypes: string[], filesTypes: string[]) => {
    let isValid = true;
    filesTypes.forEach((fileType) => {
        if (!acceptTypes.includes(fileType)) {
            isValid = false;
        }
    });
    return isValid;
};

export const mapMimeTypekey = (allowedTypes: MIME_TYPES[] | undefined): string[] => {
    if (!allowedTypes) return [];
    let keys = [];
    for (const [key, value] of Object.entries(MIME_TYPES)) {
        if (allowedTypes?.includes(value)) {
            keys.push(key);
        }
    }
    return keys;
};

// bytes
export const checkValidFileSize = (maxLimit: number, files: File[]) => {
    let isValid = true;
    files.forEach((f) => {
        if (f.size > maxLimit) {
            isValid = false;
            return;
        }
    });
    return isValid;
};
