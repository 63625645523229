import { useState } from "react";

import classNames from "classnames";
import { Form, InputGroup } from "react-bootstrap";

import { FormInputProps } from "./FormInput";

/* Password Input with addons */

const PasswordInput = ({
    name,
    placeholder,
    refCallback,
    errors,
    control,
    register,
    className,
    ...otherProps
}: FormInputProps) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <>
            <InputGroup className='mb-0'>
                <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder={placeholder}
                    name={name}
                    //id={name}
                    as='input'
                    ref={(r: HTMLInputElement) => {
                        if (refCallback) refCallback(r);
                    }}
                    className={className}
                    isInvalid={errors && errors[name] ? true : false}
                    {...(register ? register(name) : {})}
                    autoComplete={name}
                    {...otherProps}
                />
                <div
                    className={classNames("input-group-text", "input-group-password", {
                        "show-password": showPassword,
                    })}
                    data-password={showPassword ? "true" : "false"}
                >
                    <span
                        className='password-eye'
                        onClick={() => {
                            setShowPassword(!showPassword);
                        }}
                    ></span>
                </div>
            </InputGroup>

            {errors && errors[name] ? (
                <Form.Control.Feedback type='invalid' className='d-block'>
                    {errors[name]["message"]}
                </Form.Control.Feedback>
            ) : null}
        </>
    );
};

// textual form-controls—like inputs, passwords, textareas etc.

const TextualInput = ({
    type,
    name,
    placeholder,
    endIcon,
    register,
    errors,
    comp,
    rows,
    className,
    refCallback,
    ...otherProps
}: FormInputProps) => {
    if (type === "password" && endIcon) {
        return (
            <PasswordInput
                name={name}
                placeholder={placeholder}
                refCallback={refCallback}
                errors={errors!}
                register={register}
                className={className}
                {...otherProps}
            />
        );
    }
    return (
        <>
            <Form.Control
                type={type}
                placeholder={placeholder}
                name={name}
                as={comp}
                //id={name}
                ref={(r: HTMLInputElement) => {
                    if (refCallback) refCallback(r);
                }}
                className={className}
                isInvalid={errors && errors[name] ? true : false}
                {...(register ? register(name) : {})}
                rows={rows}
                {...otherProps}
            ></Form.Control>

            {errors && errors[name] ? (
                <Form.Control.Feedback type='invalid' className='d-block'>
                    {errors[name]["message"]}
                </Form.Control.Feedback>
            ) : null}
        </>
    );
};
export default TextualInput;
