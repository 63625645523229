import React, { Suspense, useState } from "react";

import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";

// constants
import { SideBarTypes } from "../constants";
import {
    selectLayoutColor,
    selectLayoutWidth,
    selectLeftSideBarTheme,
    selectLeftSideBarType,
    selectMenuPosition,
    selectShowSidebarUserInfo,
    selectTopbarTheme,
} from "../features/layout";
// hooks
import { useAppSelector } from "../hooks/reduxHooks";

// utils

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const Topbar = React.lazy(() => import("./Topbar/"));
const LeftSidebar = React.lazy(() => import("./LeftSidebar"));
const RightSidebar = React.lazy(() => import("./RightSidebar"));
const Footer = React.lazy(() => import("./Footer"));

const loading = () => <div className=''></div>;

const VerticalLayout = () => {
    const layoutColor = useAppSelector(selectLayoutColor);
    const layoutWidth = useAppSelector(selectLayoutWidth);
    const menuPosition = useAppSelector(selectMenuPosition);
    const leftSideBarTheme = useAppSelector(selectLeftSideBarTheme);
    const leftSideBarType = useAppSelector(selectLeftSideBarType);
    const showSidebarUserInfo = useAppSelector(selectShowSidebarUserInfo);
    const topbarTheme = useAppSelector(selectTopbarTheme);

    const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);

    /**
     * Open the menu when having mobile screen
     */
    const openMenu = () => {
        setIsMenuOpened((prevState) => !prevState);

        if (document.body) {
            if (isMenuOpened) {
                document.body.classList.remove("sidebar-enable");
            } else {
                document.body.classList.add("sidebar-enable");
            }
        }
    };

    const isCondensed: boolean = leftSideBarType === SideBarTypes.LEFT_SIDEBAR_TYPE_CONDENSED;

    return (
        <>
            <div id='wrapper'>
                <Suspense fallback={loading()}>
                    <Topbar openLeftMenuCallBack={openMenu} />
                </Suspense>
                <Suspense fallback={loading()}>
                    <LeftSidebar isCondensed={isCondensed} />
                </Suspense>
                <div className='content-page'>
                    <div className='content'>
                        <Container fluid>
                            <Outlet />
                        </Container>
                    </div>

                    {/*<Suspense fallback={loading()}>
                        <Footer />
                    </Suspense>*/}
                </div>
            </div>

            <Suspense fallback={loading()}>{/*<RightSidebar />*/}</Suspense>
        </>
    );
};

export default VerticalLayout;
