import { Navigate, useLocation } from "react-router-dom";

import { selectCurrentUser, selectIsLogin } from "../features/authentication";
import { useAppSelector } from "../hooks/reduxHooks";

type PrivateRouteProps = {
    component: React.ComponentType;
    roles?: string;
};

// * Private Route forces the authorization before the route can be accessed
const PrivateRoute = ({ component: RouteComponent, roles, ...rest }: PrivateRouteProps) => {
    let location = useLocation();

    const isLogin = useAppSelector(selectIsLogin);

    const currentUser = useAppSelector(selectCurrentUser);

    if (!isLogin && !currentUser) {
        return <Navigate to={"/auth/login"} state={{ from: location }} replace />;
    }

    return <RouteComponent />;
};

export default PrivateRoute;
