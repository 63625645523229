export enum SOCIAL_LOGIN_TYPES {
    "google" = "google",
    "fb" = "fb",
}

export enum SYSTEM_ROLE_TYPES {
    "ORG_ADMIN" = "ORG_ADMIN",
    "ORG_EDITOR" = "ORG_EDITOR",
    "ORG_READER" = "ORG_READER",
    "SUPER_USER" = "SUPER_USER",
}
