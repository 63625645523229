import { QUERY_OPERATOR } from "../types/enum";
import { IGetAllArgs, IQueryField, ISearchField, ISortBy } from "../types/interfaces";
import { checkObjHasValue } from "./obj";

const getSearchQueryStr = (obj: ISearchField | null): string => {
    if (!obj) {
        return "";
    }
    let queryStrArr = [];

    for (let key in obj) {
        if (obj.hasOwnProperty(key) && !!obj[key]) {
            let queryString = `&filter={"field":"${key}","operator":"like","value":"${obj[key]}"}`;
            queryStrArr.push(queryString);
        }
    }
    return queryStrArr.join("");
};
const getOperatorQueryStr = (obj: IQueryField | null, operator: QUERY_OPERATOR): string => {
    if (!obj) {
        return "";
    }
    let queryStrArr = [];

    for (let key in obj) {
        if (obj.hasOwnProperty(key) && !!obj[key]) {
            let queryString = `&filter={"field":"${key}","operator":"${operator}","value":"${obj[key]}"}`;
            queryStrArr.push(queryString);
        }
    }
    return queryStrArr.join("");
};

const getPageQueryStr = (pageConfig: Partial<IGetAllArgs>): string =>
    `?page=${pageConfig.page}&limit=${pageConfig.limit}`;

const getSortQueryStr = (sortConfig: ISortBy | ISortBy[] | undefined, defaultSort: ISortBy): string => {
    const defaultSortStr = `&order_info=${defaultSort.field} ${defaultSort.value}`;
    if (!sortConfig || !checkObjHasValue(sortConfig)) return defaultSortStr;

    // * advanced features
    if (Array.isArray(sortConfig)) {
        return "";
    }

    if (sortConfig.value === "none") {
        return defaultSortStr;
    }
    return `&order_info=${sortConfig.field} ${sortConfig.value}`;
};

export { getOperatorQueryStr, getPageQueryStr, getSearchQueryStr, getSortQueryStr };
