import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../services/store";
import {
    LayoutColor,
    LayoutTypes,
    LayoutWidth,
    MenuPositions,
    SideBarTheme,
    SideBarTypes,
    TopbarTheme,
} from "../types/enums";

const initialState = {
    layoutColor: LayoutColor.LAYOUT_COLOR_LIGHT,
    layoutType: LayoutTypes.LAYOUT_VERTICAL,
    layoutWidth: LayoutWidth.LAYOUT_WIDTH_FLUID,
    menuPosition: MenuPositions.MENU_POSITION_FIXED,
    leftSideBarTheme: SideBarTheme.LEFT_SIDEBAR_THEME_LIGHT,
    leftSideBarType: SideBarTypes.LEFT_SIDEBAR_TYPE_DEFAULT,
    showSidebarUserInfo: false,
    topbarTheme: TopbarTheme.TOPBAR_THEME_LIGHT,
    isOpenRightSideBar: false,
};

const layoutSlice = createSlice({
    name: "layout",
    initialState,
    reducers: {},
});

export default layoutSlice.reducer;

export const selectLayoutColor = (state: RootState) => state.layout.layoutColor;
export const selectLayoutType = (state: RootState) => state.layout.layoutType;
export const selectLayoutWidth = (state: RootState) => state.layout.layoutWidth;
export const selectMenuPosition = (state: RootState) => state.layout.menuPosition;
export const selectLeftSideBarTheme = (state: RootState) => state.layout.leftSideBarTheme;
export const selectLeftSideBarType = (state: RootState) => state.layout.leftSideBarType;
export const selectShowSidebarUserInfo = (state: RootState) => state.layout.showSidebarUserInfo;
export const selectTopbarTheme = (state: RootState) => state.layout.topbarTheme;
export const selectIsOpenRightSideBar = (state: RootState) => state.layout.isOpenRightSideBar;
