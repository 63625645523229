import { useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { showCustomToast } from "../../../components/common/ToastNotify";
import { FormInput, VerticalForm } from "../../../components/form";
import Loader from "../../../components/Loader";
import { useChangePwdMutation } from "../../accounts/services/accountsApiSlice";
import { IInitPwdData } from "../types/interfaces";

const LoginForm = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    //const { schemaResolver, onSubmit, isLoading, hasError } = useLogin();
    const [fetchChangePwd, { isLoading, isSuccess }] = useChangePwdMutation();

    const schemaResolver = yupResolver(
        yup.object().shape({
            old_password: yup.string().required(),
            password: yup
                .string()
                .required(t("Please enter Password"))
                .min(8)
                .notOneOf([yup.ref("old_password"), null], "Cannot be same with old password"),
            password2: yup
                .string()
                .oneOf([yup.ref("password"), null], "Passwords don't match")
                .required("This value is required."),
        })
    );
    const onSubmit = async (formData: IInitPwdData) => {
        await fetchChangePwd({
            old_password: formData.old_password,
            new_password: formData.password,
        });
    };

    useEffect(() => {
        if (isSuccess) {
            showCustomToast.success("初始密碼成功");
            navigate("/");
        }
    }, [isSuccess, navigate]);

    return (
        <>
            {isLoading && <Loader />}
            <VerticalForm<IInitPwdData>
                onSubmit={onSubmit}
                resolver={schemaResolver}
                hasBtn={false}
                //defaultValues={{ email: "adminto@coderthemes.com", password: "test" }}
            >
                <FormInput
                    label={t("舊密碼")}
                    type='password'
                    name='old_password'
                    placeholder='請輸入舊密碼'
                    containerClass={"mb-3"}
                />
                <FormInput
                    label={t("新密碼")}
                    type='password'
                    name='password'
                    placeholder='請輸入新密碼'
                    containerClass={"mb-3"}
                />
                <FormInput
                    label={t("再次輸入新密碼")}
                    type='password'
                    name='password2'
                    placeholder='請再次輸入新密碼'
                    containerClass={"mb-3"}
                />
                <div className='text-center d-grid mb-3'>
                    <Button variant='primary' type='submit' disabled={isLoading}>
                        {t("確認")}
                    </Button>
                </div>
            </VerticalForm>
        </>
    );
};
export default LoginForm;
