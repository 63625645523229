import { InputHTMLAttributes } from "react";

import { Form } from "react-bootstrap";
import { Control, FieldErrors } from "react-hook-form";
import { GroupBase, OptionsOrGroups } from "react-select";

import { IOption } from "../../types/interfaces";
import ReactSelectInput from "./ReactSelectInput";
import TextualInput from "./TextualInput";

export type FormInputProps = InputHTMLAttributes<HTMLInputElement> & {
    endIcon?: boolean;
    label?: string;
    type?: string;
    name: string;
    comp?: string;
    placeholder?: string;
    register?: any;
    errors?: FieldErrors;
    control?: Control<any>;
    className?: string;
    labelClassName?: string;
    containerClass?: string;
    textClassName?: string;
    refCallback?: any;
    action?: React.ReactNode;
    rows?: number;
    options?: OptionsOrGroups<IOption, GroupBase<IOption>>;
    isMulti?: boolean;
};

// * 尚未用到
// non-textual checkbox and radio controls
const CheckInput = ({
    type,
    label,
    name,
    placeholder,
    register,
    errors,
    comp,
    rows,
    className,
    refCallback,
    ...otherProps
}: FormInputProps) => {
    return (
        <>
            <Form.Check
                type={type}
                label={label}
                name={name}
                //id={name}
                ref={(r: HTMLInputElement) => {
                    if (refCallback) refCallback(r);
                }}
                className={className}
                isInvalid={errors && errors[name] ? true : false}
                {...(register ? register(name) : {})}
                {...otherProps}
            />

            {errors && errors[name] ? (
                <Form.Control.Feedback type='invalid' className='d-block'>
                    {errors[name]["message"]}
                </Form.Control.Feedback>
            ) : null}
        </>
    );
};

// * 尚未用到
// * Bootstrap select
// handle select controls
const SelectInput = ({
    type,
    label,
    name,
    placeholder,
    register,
    errors,
    comp,
    className,
    children,
    refCallback,
    ...otherProps
}: FormInputProps) => {
    return (
        <>
            <Form.Select
                type={type}
                label={label}
                name={name}
                //id={name}
                ref={(r: HTMLInputElement) => {
                    if (refCallback) refCallback(r);
                }}
                children={children}
                className={className}
                isInvalid={errors && errors[name] ? true : false}
                {...(register ? register(name) : {})}
                {...otherProps}
            />

            {errors && errors[name] ? (
                <Form.Control.Feedback type='invalid'>{errors[name]["message"]}</Form.Control.Feedback>
            ) : null}
        </>
    );
};

const FormInput = ({
    label,
    type,
    name,
    placeholder,
    endIcon,
    register,
    errors,
    control,
    className,
    labelClassName,
    containerClass,
    refCallback,
    children,
    action,
    rows,
    ...otherProps
}: FormInputProps) => {
    // handle input type
    const comp = type === "textarea" ? "textarea" : type === "select" ? "select" : "input";

    const hasEndIcon = endIcon !== undefined ? endIcon : true;
    if (type === "hidden") {
        return <input type={type} name={name} {...(register ? register(name) : {})} {...otherProps} />;
    }
    if (type === "select") {
        return <ReactSelectInput name={name} control={control} label={label} errors={errors} {...otherProps} />;
    }

    if (type === "checkbox" || type === "radio") {
        return (
            <Form.Group className={containerClass}>
                <CheckInput
                    type={type}
                    label={label}
                    name={name}
                    placeholder={placeholder}
                    refCallback={refCallback}
                    errors={errors}
                    register={register}
                    comp={comp}
                    className={className}
                    rows={rows}
                    {...otherProps}
                />
            </Form.Group>
        );
    }

    return (
        <>
            <Form.Group className={containerClass}>
                {label ? (
                    <>
                        <Form.Label className={labelClassName}>{label}</Form.Label>
                        {action && action}
                    </>
                ) : null}

                <TextualInput
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    endIcon={hasEndIcon}
                    refCallback={refCallback}
                    errors={errors}
                    register={register}
                    comp={comp}
                    className={className}
                    rows={rows}
                    {...otherProps}
                />
            </Form.Group>
        </>
    );
};

export default FormInput;
