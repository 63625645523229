import { useEffect } from "react";

import { Outlet, useNavigate } from "react-router-dom";

import { showCustomToast } from "../../../components/common/ToastNotify";
import Preloader from "../../../components/Preloader/Preloader";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { checkIsSuperUser, getLocalValueByKey, isResponseSuccess } from "../../../utils";
import { useGetCurrentUserQuery } from "../services/authApiSlice";
import { postLogout, selectIsLogin, setCurrentUser } from "../services/authSlice";

//* public + private route confirm user login or not
const VerifyLogin = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isLogin = useAppSelector(selectIsLogin);
    const {
        data: currentUserData,
        isLoading,
        isError,
    } = useGetCurrentUserQuery(
        {},
        {
            skip: !isLogin,
        }
    );

    useEffect(() => {
        if (currentUserData && isResponseSuccess(currentUserData)) {
            const userData = currentUserData?.data;
            dispatch(setCurrentUser(userData));
        } else if (isLogin && isError) {
            navigate("/auth/login");
            dispatch(postLogout());
        }
    }, [currentUserData, dispatch, isError, isLogin, navigate]);

    if (isLoading) {
        return (
            <div>
                <Preloader isLoading={isLoading} />
                {/*<div>Verify login isLoading...</div>*/}
            </div>
        );
    }
    return <Outlet />;
};
export default VerifyLogin;
