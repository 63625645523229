import { apiSlice } from "../../../services/api/apiSlice";
import { IApiOneBase, IGetAllArgs } from "../../../types/interfaces";
import { getPageQueryStr, getSearchQueryStr, getSortQueryStr } from "../../../utils";
import {
    IAccountsRes,
    IChangePwdReq,
    ICreateAccount,
    ICreateAccRes,
    IGetAllAccByOrgIdReq,
    IInitPwdReq,
    IUpdateAccReq,
} from "../types/interfaces";

export const accountsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllAcc: builder.query<IAccountsRes, IGetAllArgs>({
            query: ({ filter, order_info, ...pageConfig }) => {
                return {
                    url:
                        "/get_all_accounts" +
                        getPageQueryStr(pageConfig) +
                        getSearchQueryStr(filter) +
                        getSortQueryStr(order_info, { field: "created_at", value: "desc" }),
                };
            },
            providesTags: ["Account"],
        }),
        getAllAccByOrgId: builder.query<IAccountsRes, IGetAllAccByOrgIdReq>({
            query: ({ filter, order_info, org_id, ...pageConfig }) => {
                return {
                    url:
                        "/get_org_accounts" +
                        getPageQueryStr(pageConfig) +
                        `&org_id=${org_id}` +
                        getSearchQueryStr(filter) +
                        getSortQueryStr(order_info, { field: "created_at", value: "desc" }),
                };
            },
            providesTags: ["Account"],
        }),
        createAcc: builder.mutation<ICreateAccRes, Partial<ICreateAccount>>({
            query: (reqData) => ({
                url: "/create_account",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["Account"],
        }),
        updateAcc: builder.mutation<ICreateAccRes, IUpdateAccReq>({
            query: (reqData) => ({
                url: "/update_account",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["Account"],
        }),
        delAcc: builder.mutation<ICreateAccRes, IApiOneBase>({
            query: (reqData) => ({
                url: "/delete_account",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["Account"],
        }),
        changePwd: builder.mutation<ICreateAccRes, IChangePwdReq>({
            query: (reqData) => ({
                url: "/change_password",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["Account"],
        }),
        // 超級管理員 & 組織管理員可以初始化帳戶密碼
        initPwd: builder.mutation<ICreateAccRes, IInitPwdReq>({
            query: (reqData) => ({
                url: "/reset_init_password",
                method: "POST",
                body: { ...reqData },
            }),
        }),
    }),
});

export const {
    useCreateAccMutation,
    useGetAllAccQuery,
    useDelAccMutation,
    useUpdateAccMutation,
    useGetAllAccByOrgIdQuery,
    useChangePwdMutation,
    useInitPwdMutation,
} = accountsApiSlice;
