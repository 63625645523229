import { Suspense, useEffect } from "react";

import { Outlet } from "react-router-dom";

import { selectLayoutColor } from "../features/layout";
// hooks
import { useAppSelector } from "../hooks/reduxHooks";
// utils
import { changeBodyAttribute } from "../utils";

const loading = () => <div className=''></div>;

type DefaultLayoutProps = {};

const DefaultLayout = (props: DefaultLayoutProps) => {
    const layoutColor = useAppSelector(selectLayoutColor);
    useEffect(() => {
        changeBodyAttribute("data-layout-color", layoutColor);
    }, [layoutColor]);

    return (
        <Suspense fallback={loading()}>
            <Outlet />
        </Suspense>
    );
};
export default DefaultLayout;
