import { Outlet } from "react-router-dom";

import PermissionDeny from "../components/common/PermissionDeny";
import { selectUserRoles } from "../features/authentication";
import { SYSTEM_ROLE_TYPES } from "../features/authentication/types/enum";
import { useAppSelector } from "../hooks/reduxHooks";

interface IProps {
    allowRoles?: SYSTEM_ROLE_TYPES[];
}

const PermissionCheck = ({ allowRoles, ...rest }: IProps) => {
    const userRoles = useAppSelector(selectUserRoles);

    const hasPermission = userRoles?.some((role) => allowRoles?.includes(role.role_id as SYSTEM_ROLE_TYPES));

    if (!hasPermission) {
        return <PermissionDeny />;
    }
    return <Outlet />;
};
export default PermissionCheck;
