import "react-toastify/dist/ReactToastify.css";

import { Slide, toast, ToastContainer } from "react-toastify";

export const showCustomToast = {
    warn: (message: string) => toast.warn(message),
    success: (message: string) => toast.success(message),
    error: (message: string) => {
        return message && toast.error(message);
    },
};

const ToastNotify = () => {
    return (
        <ToastContainer
            position='bottom-left'
            limit={3}
            autoClose={3000}
            //autoClose={60000}
            hideProgressBar={true}
            //newestOnTop
            closeOnClick
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
            transition={Slide}
            theme='colored'
        />
    );
};
export default ToastNotify;
