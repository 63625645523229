import styles from "./LoginForm.module.scss";

import classNames from "classnames";
import { Button, Form } from "react-bootstrap";
import ClientCaptcha from "react-client-captcha";
import { useTranslation } from "react-i18next";

import { FormInput, VerticalForm } from "../../../components/form";
import Loader from "../../../components/Loader";
import useLogin from "../hooks/useLogin";
import { IUserData } from "../types/interfaces";

const LoginForm = () => {
    const { t } = useTranslation();

    const { schemaResolver, onSubmit, isLoading, setCaptChCode, setCodeInput, codeInput } = useLogin();

    return (
        <>
            {isLoading && <Loader />}
            <VerticalForm<IUserData>
                onSubmit={onSubmit}
                resolver={schemaResolver}
                hasBtn={false}
                //defaultValues={{ email: "adminto@coderthemes.com", password: "test" }}
            >
                <FormInput
                    type='text'
                    name='id'
                    label={t("ID")}
                    placeholder={t("輸入使用者ID")}
                    containerClass={"mb-3"}
                />
                <FormInput
                    label={t("密碼")}
                    type='password'
                    name='password'
                    placeholder='輸入您的密碼'
                    containerClass={"mb-3"}
                />
                <Form.Group className='mb-3'>
                    <Form.Label className={""}>驗證碼</Form.Label>
                    <div className={styles.codeWrapper}>
                        <input
                            type='text'
                            className={classNames("form-control", styles.codeInput)}
                            placeholder='輸入右方驗證碼'
                            value={codeInput || ""}
                            onChange={(e) => setCodeInput(e.target.value)}
                        />
                        <ClientCaptcha
                            captchaCode={(code: string) => setCaptChCode(code)}
                            //retryIconSize={20}
                            height={37.8}
                            backgroundColor='#F7F7F7'
                            retry={false}
                            chars='abcdefghjkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ23456789'
                        />
                    </div>
                </Form.Group>
                <div className='text-center d-grid mb-3'>
                    <Button variant='primary' type='submit' disabled={isLoading}>
                        {t("登入")}
                    </Button>
                </div>
            </VerticalForm>
        </>
    );
};
export default LoginForm;
