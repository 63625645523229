export enum SUBMIT_MODE {
    "CREATE" = "CREATE",
    "EDIT" = "EDIT",
    "DELETE" = "DELETE",
    "READONLY" = "READONLY",
}

export enum QUERY_OPERATOR {
    "EQUAL" = "=",
    "NEQ" = "!=",
    "GREATER" = ">",
    "LESS" = "<",
    "GREATER_EQ" = ">=",
    "LESS_EQ" = "<=",
    "IN" = "in",
    "LIKE" = "like",
}

export enum RES_STATUS {
    "SUCCESS" = 1,
    "FAIL" = -1,
    "CLIENT_ERROR" = 0,
}

export enum MAP_COLUMN_NAME {
    "org_name" = "組織名稱",
    "name" = "組織名稱",
    "id" = "ID",
    "email" = "Email",
    "username" = "使用者名稱",
    "title" = "標題",
    "description" = "描述",
    "content" = "內容",
    "game_name" = "遊戲名稱",
    "CPU_Archit" = "CPU 架構",
    "algorithm" = "演算法",
    "game_id" = "遊戲 ID",
    "system" = "作業系統",
    "version" = "版本號",
    "checksum" = "checksum",
    "download_url" = "下載的網址",
    "project" = "專案",
    "default_game" = "預設遊戲",
    "Auth_count" = "授權數量",
    "setting_type" = "設定類型",
    "setting_history" = "設定歷史",
    "subject" = "主旨",
}

export enum TOKEN_ERROR_KEY {
    "REFRESH_TOKEN_EXPIRED" = "TOKEN.REFRESH_ERR",
    "ACCESS_TOKEN_EXPIRED" = "TOKEN.INVALID_ERROR",
}

export enum MIME_TYPES {
    pdf = "application/pdf",
    txt = "text/plain",
    doc = "application/msword",
    docx = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    mp3 = "audio/mpeg",
    mpeg = "video/mpeg",
    mp4 = "video/mp4",
    ppt = "application/vnd.ms-powerpoint",
    pptx = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    wav = "audio/wav",
}

export enum UPLOAD_STATUS {
    SUCCESS_100 = "100",
}

export enum UPLOAD_POLL_TIME {
    M_SEC_0 = 0,
    M_SEC_1000 = 1000,
}
