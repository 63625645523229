const checkObjHasValue = (obj: any): boolean => {
    for (let key in obj) {
        if (!obj[key]) {
            return false;
        }
    }
    return true;
};

export { checkObjHasValue };
