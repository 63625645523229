const groupByFields = (array: Array<any>, f: any): Array<any> => {
    /*
    params description :
        f : function which returnf the array of fields 
        e.g. :  (item) => {
            return [itemField1, itemField2];
        }
        array : array of data to group e.g. : [{...}, {...}]       
    */
    var groups: { [key: string]: any[] } = {};
    array.forEach((o) => {
        var group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
    });

    return Object.keys(groups).map((group) => {
        return groups[group];
    });
};

/**
 * split array into chunks
 * @param array - array to split
 * @param chunkSize - chunk size
 * @returns
 */
const splitArray = (array: Array<any>, chunkSize: number) => {
    const chunks = Array(Math.ceil(array.length / chunkSize))
        .fill(1)
        .map((_, index) => index * chunkSize)
        .map((begin) => array.slice(begin, begin + chunkSize));
    return chunks;
};

//interface ITableSizePerPageList {
//    totalCount: number | undefined;
//    defaultPageSize: number;
//}
//const getTableSizePerPageList = ({
//    totalCount = 1000,
//    defaultPageSize,
//}: ITableSizePerPageList): { text: string; value: number }[] => {
//    const max = defaultPageSize * 6;
//    const middle = defaultPageSize * 3;
//    const min = defaultPageSize * 2;
//    const defaultList = { text: defaultPageSize.toString(), value: defaultPageSize };
//    const minList = {
//        text: min.toString(),
//        value: min,
//    };
//    const middleList = {
//        text: middle.toString(),
//        value: middle,
//    };
//    const maxList = {
//        text: max.toString(),
//        value: max,
//    };
//    const baseList = {
//        text: "All",
//        value: totalCount,
//    };
//    let temp: { text: string; value: number }[] = [];
//    if (max < totalCount) {
//        temp = [minList, middleList, maxList];
//    } else if (middle < totalCount) {
//        temp = [minList, middleList];
//    } else if (min < totalCount) {
//        temp = [minList];
//    } else if (defaultPageSize >= totalCount) {
//        return [baseList];
//    }
//    return [defaultList, ...temp, baseList];
//};

export { groupByFields, splitArray };
