import { Card } from "react-bootstrap";

const PermissionDeny = () => {
    return (
        <Card className='border border-danger'>
            <Card.Body className='text-danger'>
                <Card.Title as='h5' className='text-danger'>
                    Sorry
                </Card.Title>
                <Card.Text>您目前沒有權限進入。</Card.Text>
            </Card.Body>
        </Card>
    );
};
export default PermissionDeny;
