// For Default import Theme.scss
import "./assets/scss/Theme.scss";

import ToastNotify from "./components/common/ToastNotify";
// helpers
// all routes
import Routes from "./routes/Routes";

const App = () => {
    return (
        <>
            <Routes />
            <ToastNotify />
        </>
    );
};

export default App;
