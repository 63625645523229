import styles from "./Preloader.module.scss";

import { Spinner } from "react-bootstrap";

interface IProps {
    isLoading: boolean;
    value?: string;
}

const Preloader = ({ isLoading, value }: IProps) => {
    return (
        <div>
            {isLoading && (
                <div className={styles.overlay}>
                    <div className={styles.wrapper}>
                        <Spinner variant={"primary"} animation='border' />
                        {value && <div className={styles.spinnerContent}>{value}</div>}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Preloader;
